import React from 'react'
import WalletDetails from '../../../Components/Driver/Wallet/WalletDetails'
import DriverNavBar from '../../../Components/Navbar/DriverNavBar'

function DriverWalletPage() {
  return (
    <>
    <DriverNavBar/>   
    <WalletDetails/>
 
    </>
  )
}

export default DriverWalletPage
